<template lang="pug">
  .agentbase
    div(v-show="!preload")
      transition(name="fade" mode="out-in")
        .container-logo(v-if="!turnId")
          img.stylelogo.d-block(:src="Logo" v-openlog v-show="showMessage")
          h5(
            class="container-text"
            style="position: absolute; margin-top: 30vh; text-align: center; color: firebrick;"
            v-show="showMessage"
          ) La versión de aplicación utilizada por el cliente es menor a 4.0, por tanto, no se puede conectar a la videollamada.
        Meeting(v-if="turnId" meetType="agent" :meetId="meetingId" @endMeet="endMeet" :username="username" @attendees="managePeople")
    div( v-if="preload" style="position: absolute; width: 50%; right: 22%;")
      div( style="text-align: center;")
        Loading
        h2 Conectando...
      div(class="styleDivData")
        DivInfoCall(meetType="agent" v-show="!showMessage")
    div(class="styleDivData" v-else)
      DivInfoCall(meetType="agent" v-show="!showMessage")
</template>

<script>
import _get from "lodash/get";
import { mapState, mapActions } from "vuex";
import Meeting from "./components/Meeting";
import LogoColmedica from "@/assets/colmedica_logo.png";
import DivInfoCall from "./components/DivInfoCall";
import Loading from "../components/Loading.vue"

export default {
  name: "AgentSilver",

  components: {
    Meeting,
    DivInfoCall,
    Loading
  },

  data: () => ({
    LogoColmedica,
    preload: true,
    showMessage: false
  }),

  watch: {
    turnId(val) {
      // if (!val) this.$router.push({ name: "CanceledAgent" });
      if (!val) {
        setTimeout(() => {
          this.showMessage = true;
        }, 2000);
      } 
      
    }
  },

  computed: {
    ...mapState(["turn", "env", "turnCoorelation"]),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    username() {
      return (
        `${this.turn.worker?.actualUser?.uUser?.firstName || ""} ${this.turn
          .worker?.actualUser?.uUser?.lastName || ""}`.trim() || "Agente"
      );
    },

    meetingId() {
      return this.turnCoorelation?.code_title;
    },

    turnId() {
      return _get(this.turn, "code");
    }
  },

  methods: {
    ...mapActions({
      cancelTurn: "cancelTurn",
      verify_client_data: "verify_client_data",
      stopCheck: "recording/stopCheck",
      putLog: "log/put",
    }),

    async endMeet() {
      this.stopCheck();
      await this.cancelTurn({ local: true });
      this.$router.push({ name: "CanceledAgent" });
    },

    managePeople(val){
      this.preload = val;
    }
  }
};
</script>

<style scoped>
.agentbase {
  height: 100vh;
  width: 100%;
}

.styleDivData {
  width: 300px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
  left: 30px;
  top: 120px;
}

.dk-meet {
  height: 750px !important;
}

.container-text {
  width: calc(100% - 600px - 30px);
}
@media only screen and (max-width: 1800px) {
  .agentbase {
    display: flex;
    align-items: flex-start;
    /* margin-top: 6rem; */
  }
  .styleDivData {
    top: auto;
    left: auto;
    position: initial;
    display: inline;
    left: 30px;
    top: 0;
    height: auto !important;
    margin-top: 0.5rem;
    margin: 5% 0 0 2% !important;
  }
  .container-text {
    width: calc(100% - 40px);
  }
  .dk-meet {
    height: 750px !important;
  }
  .container-client-data {
    height: 100% !important;
  }
}

@media screen and (max-width: 1300px) {
  .agentbase {
    flex-wrap: wrap;
  }
  .dk-meet {
    margin-bottom: 9rem;
  }
}
</style>
