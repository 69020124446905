<template lang="pug">
  .position-relative.m-0.container-client-data

    .col-12.m-0.p-1
      //- b(class="my-1 d-block text-center") Estado del video.
      p(class="containerInfo") Conexión a la videoconferencia:
        span.ml-2.isCircle.led-green(v-if="clientConnected")
        span.ml-2.isCircle.led-red(v-else)
      p(class="containerInfo") Agente: 
        span.ml-2.isCircle.led-green(v-if="personalVideo")
        span.ml-2.isCircle.led-red(v-else)
      p(class="containerInfo") Cliente conectado:
        span.ml-2.isCircle.led-green(v-if="isMeetingWithTwo")
        span.ml-2.isCircle.led-red(v-else)
      p(class="containerInfo") Cliente con video:
        span.ml-2.isCircle.led-green(v-if="externalVideos")
        span.ml-2.isCircle.led-red(v-else)
      p(class="containerInfo") Compartir pantalla:
        span.ml-2.isCircle.led-green(v-if="haveSharedVideo")
        span.ml-2.isCircle.led-red(v-else)

    .col-12.m-0.p-1
      b(class="my-1 d-block text-center") Datos de cliente
      p(class="containerInfo") Sistema: {{ system }}
      p(class="containerInfo") Navegador: {{ navegador }}
      p(class="containerInfo") Dispositivo: {{ platform }}
      p(class="containerInfo") Entorno BSIX: {{ entorno }}
      p(class="containerInfo") Nombres: {{ name }}
      p(class="containerInfo") Apellidos: {{ last_name }}
      p(class="containerInfo") Tipide: {{ tipide }}
      p(class="containerInfo") Numide:
        span(style="display: inline-block;")#numide.ml-1 {{ numide }}
        b-button(@click="() => copyElement('numide')" variant="outline" style="display: inline-block;").btn-sm.p-0.ml-1
          img(src="../../../assets/unnamed.png" alt="Copiar" style="width: 20px; object-fit: cover;")
      p(v-if="showMessage" style="font-style: italic;").text-success.m-0.p-0 {{ showMessage }}
      p(class="containerInfo") Numero de solicitud:
        span(style="display: inline-block;")#SOM.ml-1 {{ SOM }}
        b-button(@click="() => copyElement('SOM')" variant="outline" style="display: inline-block;").btn-sm.p-0.ml-1
          img(src="../../../assets/unnamed.png" alt="Copiar" style="width: 20px; object-fit: cover;")
      p(v-if="showMessageSOM" style="font-style: italic;").text-success.m-0.p-0 {{ showMessageSOM }}
      p(class="containerInfo" v-if="phoneNumber") Número de celular:
        span(style="display: inline-block;")#phone.ml-1 {{ phoneNumber }}
        b-button(@click="() => copyElement('phone')" variant="outline" style="display: inline-block;").btn-sm.p-0.ml-1
          img(src="../../../assets/unnamed.png" alt="Copiar" style="width: 20px; object-fit: cover;")
      p(v-if="showMessageOther" style="font-style: italic;").text-success.m-0.p-0 {{ showMessageOther }}
      p(class="containerInfo" v-if="turnCreation") Hora turno emitido: {{ turnCreation }}
      p(class="containerInfo" v-if="turnTimeElapsed") Tiempo de espera cliente: {{ turnTimeElapsedText }}
      p(class="containerInfo") Fuente: {{ font }}
      p(class="containerInfo") Servicio: {{ service }}

</template>
<script>
import { mapState } from "vuex";
import _get from "lodash/get";
import CopyIcon from "mdi-vue/ContentCopy";
import moment from "moment";

export default {
  name: "DivInfoCall",
  props: {
    meetType: {
      default: null
    }
  },
  components: {
    CopyIcon
  },
  data: () => ({
    default_response: "No se tiene",
    showMessage: "",
    showMessageOther: "",
    showMessageSOM: "",
  }),
  computed: {
    ...mapState({
      dclient_data: state => state.dclient_data,
      videosIds: state => state.meeting.videosIds,
      attendees: state => state.meeting.attendees,
      turnCoorelation: state => state.turnCoorelation
    }),

    turnCreation() {
      return this.turnCoorelation?.created_at
        ? moment.unix(this.turnCoorelation.created_at).format("hh:mm a")
        : null;
    },

    turnTimeElapsed() {
      if (!this.turnCoorelation?.created_at) return null;
      const now = moment();
      const turn = moment.unix(this.turnCoorelation.created_at);
      return now.diff(turn, "seconds");
    },

    turnTimeElapsedText() {
      let minutes = Math.floor(this.turnTimeElapsed / 60);
      let seconds = this.turnTimeElapsed - minutes * 60;
      return `00:${minutes < 10 ? "0" + minutes : minutes}:${
        seconds < 10 ? "0" + seconds : seconds
      }`;
    },

    navegador() {
      return `${_get(this.dclient_data, "client.br.n") || ""} ${_get(
        this.dclient_data,
        "client.br.v"
      ) || ""}`.trim();
    },

    system() {
      return `${_get(this.dclient_data, "client.os.n") || ""} ${_get(
        this.dclient_data,
        "client.os.vn"
      ) || ""} (${_get(this.dclient_data, "client.os.v") || ""})`.trim();
    },

    platform() {
      return `${_get(this.dclient_data, "client.pl") || ""}`.trim();
    },
    entorno() {
      return `${_get(this.dclient_data, "client.bsix.e") || ""}. V: ${_get(
        this.dclient_data,
        "client.bsix.v"
      ) || ""}`.trim();
    },
    clientConnected() {
      return Array.isArray(this.attendees) && this.attendees.length > 1;
    },

    name() {
      return _get(this.dclient_data, "na", this.default_response);
    },
    phoneNumber() {
      return _get(this.dclient_data, "phone", this.default_response);
    },
    service() {
      return _get(this.dclient_data, "sv", this.default_response);
    },
    last_name() {
      return _get(this.dclient_data, "ap", this.default_response);
    },
    numide() {
      return _get(this.dclient_data, "nu", this.default_response);
    },
    tipide() {
      return _get(this.dclient_data, "ti", this.default_response);
    },
    SOM() {
      return _get(this.dclient_data, "so", this.default_response);
    },
    isAgent() {
      return this.meetType === "agent";
    },
    personalVideo() {
      let result = this.videosIds.filter(vid => vid.localTile);
      return _get(result, "[0].active", false);
    },
    externalVideos() {
      let result = this.videosIds.filter(
        vid => !vid.localTile && !vid.isContent
      );
      return _get(result, "[0].active", false);
    },
    isMeetingWithTwo() {
      return this.attendees.length >= 2;
    },
    haveSharedVideo() {
      let result = this.videosIds.filter(vid => vid.isContent);
      return _get(result, "[0].active", false);
    },

    font() {
      return this.dclient_data?.fu
        ? this.dclient_data.fu === "ad/"
          ? "AutoServicio"
          : this.dclient_data.fu === "app"
          ? "App"
          : this.dclient_data.fu === "web"
          ? "Web"
          : this.dclient_data.fu
        : "No hay fuente";
    }
  },
  methods: {
    copyElement(id = "numide") {
      var aux = document.createElement("input");

      aux.setAttribute("value", document.getElementById(id).innerHTML);
      aux.setAttribute("class", "none");
      document.body.appendChild(aux);

      aux.select();

      document.execCommand("copy");
      document.body.removeChild(aux);

      id !== "SOM"
        ? id === "numide" ? (this.showMessage = "Texto copiado al portapapeles") : (this.showMessageOther = "Texto copiado al portapapeles")
        : (this.showMessageSOM = "Texto copiado al portapapeles");
      const timer = setTimeout(() => {
        this.showMessage = "";
        this.showMessageSOM = "";
        this.showMessageOther = "";
        clearTimeout(timer);
      }, 2000);
    }
  }
};
</script>

<style scoped>
.container-client-data {
  height: 100%;
  width: 100%;
  background-color: rgb(229 238 238);
  border-radius: 10px;
  /* overflow-y: auto; */
}
.containerInfo {
  font-size: 12px;
  margin: 0.3rem 0px;
}

.isCircle {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}
.isCircle.isGreen {
  background-color: rgb(62, 206, 62);
}
.isCircle.isRed {
  background-color: red;
}
</style>
